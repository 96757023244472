<template>
  <div class="message-item mb-14px">
    <div class="text-[#A8ABB2] leading-38px title" :style="{ width: width }">{{ props.title + '：' }}</div>
    <div v-if="props.description == null" ref="ellipsis" class="description">
      <slot name="description" />
    </div>
    <div v-else ref="ellipsis" class="leading-38px ellipsis">
      <el-tooltip v-if="tooltip" :content="props.description" placement="top-start">
        {{ props.description }}
      </el-tooltip>
      <span v-else>{{ props.description }}</span>
    </div>
  </div>
</template>

<script setup>
import { nextTick, ref } from 'vue';

const props = defineProps({
  title: '',
  description: '',
  width: '158px'
})

const tooltip = ref(false)
const ellipsis = ref(null)

// 判断是否需要显示tooltip
function isEllipsis(){
  tooltip.value = ellipsis.value?.scrollWidth > ellipsis.value?.clientWidth
}

nextTick(()=>{
  isEllipsis()
})

</script>
<style lang="scss" scoped>
.message-item {
  display: flex;
  align-items: flex-start;
  font-size: 14px;

  .title {
    width: 158px;
    white-space: nowrap;
  }
}

.description {
  flex: 1;
  width: 0;
  word-break: break-all;
}
</style>
