
/** 将对象所有属性值设置为undefined */
export function clearObjectValue(data) {
  const keys = Object.keys(data);
  const obj = {};
  keys.forEach(item => {
    obj[item] = undefined;
  });
  Object.assign(data, obj);
}

/* 
  数组字典匹配
  id：要匹配的值；
  arr：要匹配的字典；
  key：要匹配的字段；
*/
export function mateArrDict(id, arr, key){
  let data = arr.filter(item=>{
    return item[key] === id
  })

  return data[0]
}

// 岗位匹配
export function matchPostSele(ids) {
  const postOption = reactive(JSON.parse(sessionStorage.getItem('postSelectData')))
  console.log(postOption);
  let arr = []
  for (let i = 0; i < ids.length; i++) {
    for (let j = 0; j < postOption.length; j++) {
      if (ids[i] == postOption[j].postId) {
        arr.push(postOption[j].postName)
        break
      }
    }
  }
  return arr.join(',')
}

// 字符串参数转对象
export function stringObj(data) {
  let arr = data.split('?')[1].split('&')
  let obj = {}
  arr.forEach(item=>{
    let keys = item.split('=')
    if(keys[0] === 'pageNum' || keys[0] === 'pageSize'){
      obj[keys[0]] = +keys[1]
    } else {
      obj[keys[0]] = keys[1]
    }
  })
  return obj
}

// 省市县过滤第三级
export function deleteThreeRegion(data) {
  let arr = data.map(item=>{
    item.children = item.children.map( childs => {
      return {
        label: childs.label,
        value: childs.value,
        level: childs.level,
        pid: childs.pid
      }
    })
    return item
  })
  return arr
}