<template>
  <dialog-model :title="title" :show="show" @confirm="confirm" @close="cancel">
    <template #content>
      <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="登录账号" prop="userName">
          <el-input v-model="form.userName" size="large" placeholder="请输入登录账号" />
        </el-form-item>
        <el-form-item label="用户名称" prop="nickName">
          <el-input v-model="form.nickName" size="large" placeholder="请输入用户名称" />
        </el-form-item>
        <el-form-item label="用户类型" prop="nickName" v-if="!type">
          <el-select v-model="form.userType" size="large" clearable>
            <el-option v-for="item of sys_user_type" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item v-if="!id" label="登录密码" prop="password">
          <el-input v-model="form.password" type="password" size="large" placeholder="请输入用户名称" show-password />
        </el-form-item>
        <el-form-item label="联系方式" prop="phone">
          <el-input v-model="form.phone" size="large" placeholder="请输入联系方式" />
        </el-form-item>
        <el-form-item label="邮箱" prop="email">
          <el-input v-model="form.email" size="large" placeholder="请输入邮箱" />
        </el-form-item>
        <el-form-item label="角色分配" v-if="!type">
          <el-select v-model="form.roleIds" multiple clearable size="large">
            <el-option v-for="item of options.roles" :key="item.roleId" :label="item.roleName"
              :value="item.roleId"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="状态" v-if="!type">
          <el-radio-group v-model="form.status" class="ml-4">
            <el-radio label="0" size="large">开启</el-radio>
            <el-radio label="1" size="large">禁用</el-radio>
          </el-radio-group>
        </el-form-item>
      </el-form>
    </template>
    <template #btn>
      <div class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </dialog-model>
</template>

<script setup>
import { reactive, ref, watch } from 'vue';
import { REGEXP_PHONE, REGEXP_EMAIL } from '@/config/regexp'
import { getUser, addUser, updateUser, updateEditInfo } from "@/api";
import { clearObjectValue } from "@/utils/common"

const { proxy } = getCurrentInstance();
const { sys_user_type } = proxy.useDict("sys_user_type");

const props = defineProps({
  title: String,
  show: Boolean,
  id: String || Number,
  type: String
})

const emit = defineEmits()
const formRef = ref(null);

// 表单
const form = reactive({
  userName: '',
  nickName: '',
  userType: '',
  password: '',
  phone: '',
  email: '',
  roleIds: [],
  status: '0'
})

const password = ref('')

// 下拉框字典
const options = reactive({
  roles: []
})

// 监听modal关闭
watch(
  () => props.show,
  newValue => {
    if (newValue) {
      if(props.type) {
        const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
        form.userName = userInfo.userName
        form.nickName = userInfo.nickName
        form.phone = userInfo.phone
        form.email = userInfo.email
      } else {
        getAccountInfo();
      }
      !props.id && (form.status = '0');
    } else {
      emit('cancel')
      clearObjectValue(form)
    }
  }
);

// 表单验证
const rules = reactive({
  userName: { required: true, message: '请输入登录账号', trigger: 'blur' },
  nickName: { required: true, message: '请输入用户名称', trigger: 'blur' },
  password: { required: true, message: '请输入登录密码', trigger: 'blur' },
  phone: { required: true, pattern: REGEXP_PHONE, message: '电话号码格式错误', trigger: 'blur' },
  email: { pattern: REGEXP_EMAIL, message: '邮箱格式错误', trigger: 'blue' },
})

// 获取用户信息
function getAccountInfo() {
  getUser(props.id).then(res => {
    // 角色字典
    options.roles = res.roles
    // 赋值回显输入框
    if (res.data) {
      let keys = Object.keys(form)
      keys.forEach(item => {
        if (item !== "roleIds" && item !== 'userType') {
          form[item] = res.data[item]
        } else if(item == 'userType') {
          form[item] = String(res.data.userType)
        } else {
          form[item] = res[item]
        }
      })
      console.log(form);
      password.value = res.data.password
      form.password = ''
    }
  })
  delete rules.password
}

// 确认回调
function confirm() {
  proxy.$refs["formRef"].validate(valid => {
    if (valid) {
      props.id ? edit() : add()
    }
  });
}

// 取消回调
function cancel() {
  emit('cancel')
}

// 新建客户
function add() {
  addUser(form).then(res => {
    proxy.$modal.msgSuccess("新建成功");
    emit('confirm')
  })
}

// 编辑客户
function edit() {
  form.userId = props.id
  props.type ? updateEditInfo(form).then(res => {
    proxy.$modal.msgSuccess("编辑成功");
    res.data && sessionStorage.setItem('userInfo', res.data)
    emit('confirm')
  }) : updateUser(form).then(res => {
    proxy.$modal.msgSuccess("编辑成功");
    emit('confirm')
  })
}
</script>

<style lang='scss' scoped></style>