import request from '@/utils/request'

// 登录方法
export function login(username, password, code, uuid) {
  const data = {
    username,
    password,
    code,
    uuid
  }
  return request({
    url: '/auth/login',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 注册方法
export function register(data) {
  return request({
    url: '/register',
    headers: {
      isToken: false
    },
    method: 'post',
    data: data
  })
}

// 获取用户详细信息
export function getInfo() {
  return request({
    url: '/system/user/getInfo',
    method: 'get'
  })
}

// 退出方法
export function logout() {
  return request({
    url: '/auth/logout',
    method: 'delete'
  })
}

// 获取图片验证码
export function getCodeImg() {
  return request({
    url: '/code',
    headers: {
      isToken: false
    },
    method: 'get',
    timeout: 20000
  })
}

// 获取手机验证码
export function getPhoneCode(phoneNumber) {
  return request({
    url: `/auth/sms/loginCode/${phoneNumber}`,
    headers: {
      isToken: false
    },
    method: 'post'
  })
}

//验证码登录
export function smsLoging(phoneNumber, code) {
  return request({
    url: `/auth/sms/login`,
    headers: {
      isToken: false
    },
    method: 'post',
    data: { phoneNumber, code }
  })
}