import request from '@/utils/request'

// 获取字典
export function getDropdownSelect(url, key) {
  return request({
    url: `/${url}/getDropdownSelect/${key}`,
    method: 'get'
  })
}

// 天眼查企业信息搜索
export function companySearch(params){
  return request({
    url: `/survey/company/search`,
    method: 'get',
    params: params,
  })
}