/** 手机号码正则 */
export const REGEXP_PHONE = /^[1][3-9][0-9]{9}$/;

/** 电话或手机 */
export const REGEXP_PHONE_TELEPHONE = /^([1][3-9][0-9]{9})$|^((\d{3,4}-)?\d{7,8})$/;

/** 电话 */
export const REGEXP_TELEPHONE = /^((\d{3,4}-)?\d{7,8})$/;

/** 邮箱正则 */
export const REGEXP_EMAIL = /^\w+([-+.]\w+)*@\w+([-.]\w+)*\.\w+([-.]\w+)*$/;

/** 身份证 */
export const REGEXP_IDENTITY_CARD =
  /^[1-9]\d{7}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}$|^[1-9]\d{5}[1-9]\d{3}((0\d)|(1[0-2]))(([0|1|2]\d)|3[0-1])\d{3}([0-9]|X)$/;

/** 大小写字母+数字+最少6-20位的密码验证 */
export const REGEXP_PWD = /^[A-Za-z\d]{6,20}$/;

/** 6位数字验证码正则 */
export const REGEXP_CODE_SIX = /^\d{6}$/;

/** 4位数字验证码正则 */
export const REGEXP_CODE_FOUR = /^\d{4}$/;

/** url链接正则 */
export const REGEXP_URL = /^((ht|f)tps?):\/\/[\w-]+(\.[\w-]+)+([\w\-.,@?^=%&:/~+#]*[\w\-@?^=%&/~+#])?$/;

/** 不超过10个字符 */
export const REGEXP_CHART_LIMIT_10 = /^.{0,10}$/;

/** 不超过20个字符 */
export const REGEXP_CHART_LIMIT_20 = /^.{0,20}$/;

/** 不超过30个字符 */
export const REGEXP_CHART_LIMIT_30 = /^.{0,30}$/;

/** 不超过50个字符 */
export const REGEXP_CHART_LIMIT_50 = /^.{0,50}$/;

/** 不超过100个字符 */
export const REGEXP_CHART_LIMIT_100 = /^.{0,100}$/;

/** 正整数 */
export const REGEXP_POSITIVE_INTEGER = /^[1-9][0-9]*$/;