import { createWebHistory, createRouter } from "vue-router";
/* Layout */
import Layout from "@/layout";

/**
 * Note: 路由配置项
 *
 * hidden: true                     // 当设置 true 的时候该路由不会再侧边栏出现 如401，login等页面，或者如一些编辑页面/edit/1
 * alwaysShow: true                 // 当你一个路由下面的 children 声明的路由大于1个时，自动会变成嵌套的模式--如组件页面
 *                                  // 只有一个时，会将那个子路由当做根路由显示在侧边栏--如引导页面
 *                                  // 若你想不管路由下面的 children 声明的个数都显示你的根路由
 *                                  // 你可以设置 alwaysShow: true，这样它就会忽略之前定义的规则，一直显示根路由
 * redirect: noRedirect             // 当设置 noRedirect 的时候该路由在面包屑导航中不可被点击
 * name:'router-name'               // 设定路由的名字，一定要填写不然使用<keep-alive>时会出现各种问题
 * query: '{"id": 1, "name": "ry"}' // 访问路由的默认传递参数
 * roles: ['admin', 'common']       // 访问路由的角色权限
 * permissions: ['a:a:a', 'b:b:b']  // 访问路由的菜单权限
 * meta : {
    noCache: true                   // 如果设置为true，则不会被 <keep-alive> 缓存(默认 false)
    title: 'title'                  // 设置该路由在侧边栏和面包屑中展示的名字
    icon: 'svg-name'                // 设置该路由的图标，对应路径src/assets/icons/svg
    breadcrumb: false               // 如果设置为false，则不会在breadcrumb面包屑中显示
    activeMenu: '/system/user'      // 当路由设置了该属性，则会高亮相对应的侧边栏。
  }
 */

// 公共路由
export const constantRoutes = [
  {
    path: "/redirect",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "/redirect/:path(.*)",
        component: () => import("@/views/redirect/index.vue"),
      },
    ],
  },
  {
    path: "/login",
    name: "login",
    component: () => import("@/views/login"),
    hidden: true,
  },
  {
    // 智库文档
    path: "/knownledge",
    name: "knownledge",
    component: () => import("@/views/knownledge"),
    hidden: true,
  },
  {
    // 智库文档上传
    path: "/knownledge/upload",
    name: "knownledgeUpload",
    component: () => import("@/views/knownledge/upload/index.vue"),
    hidden: true,
  },
  {
    // 智库文档详情
    path: "/knownledge/detail",
    name: "knownledgeDetail",
    component: () => import("@/views/knownledge/detail/index.vue"),
    hidden: true,
  },
  {
    // 答卷页
    path: "/answer/change-maturity/:id",
    name: "answer",
    component: () => import("@/views/answer/change-maturity"),
    hidden: true,
  },
  {
    // 结果反馈页
    path: "/answer/result/:id",
    name: "result",
    component: () => import("@/views/answer/result"),
    hidden: true,
  },
  {
    // 申请详细报告页
    path: "/answer/apply/:id",
    component: () => import("@/views/answer/apply"),
    hidden: true,
  },
  {
    path: "/register",
    name: "register",
    component: () => import("@/views/register"),
    hidden: true,
  },
  {
    path: "/:pathMatch(.*)*",
    component: () => import("@/views/error/404"),
    hidden: true,
  },
  {
    path: "/401",
    component: () => import("@/views/error/401"),
    hidden: true,
  },
  {
    path: "",
    component: Layout,
    redirect: "/evaluation/product",
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/system/role/add",
            component: () => import("@/views/system/role/add/index"),
            name: "roleAdd",
            hidden: true,
            meta: { title: "新建角色", activeMenu: "/system/role" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/system/role/edit",
            component: () => import("@/views/system/role/edit/index"),
            name: "roleEdit",
            hidden: true,
            meta: { title: "编辑角色", activeMenu: "/system/role" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/system/role/look",
            component: () => import("@/views/system/role/look/index"),
            name: "roleLook",
            hidden: true,
            meta: { title: "查看角色", activeMenu: "/system/role" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/system/dict/config/:id",
            component: () => import("@/views/system/dict/config/index"),
            name: "roleDict",
            hidden: true,
            meta: { title: "字典数据", activeMenu: "/system/dict" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/config/questionnaire/feedback/:id",
            component: () =>
              import("@/views/config/questionnaire/feedback/index"),
            name: "feedback",
            hidden: true,
            meta: { title: "反馈结果", activeMenu: "/config/questionnaire" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/config/questionnaire/config",
            component: () =>
              import("@/views/config/questionnaire/config/index"),
            name: "config",
            hidden: true,
            meta: { title: "配置问卷", activeMenu: "/config/questionnaire" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/evaluation/that-person/detail/:id",
            component: () => import("@/views/evaluation/that-person/detail"),
            name: "thatPersonDetail",
            hidden: true,
            meta: {
              title: "个人测评详情",
              activeMenu: "/evaluation/that-person",
            },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/bi/benchmark/contrast",
            component: () => import("@/views/bi/benchmark/contrast/index"),
            name: "biContrast",
            hidden: true,
            meta: { title: "对比标杆企业", activeMenu: "/bi/benchmark" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/evaluation/sending",
            component: () => import("@/views/evaluation/sending/index"),
            name: "evaluationSending",
            hidden: true,
            meta: { title: "发送问卷", activeMenu: "/evaluation/product" },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/evaluation/enterprise/detail",
            component: () =>
              import("@/views/evaluation/enterprise/detail/index"),
            name: "enterpriseDetail",
            hidden: true,
            meta: {
              title: "企业测评详情",
              activeMenu: "/evaluation/enterprise",
            },
          },
        ],
      },
    ],
  },
  {
    path: "",
    component: Layout,
    hidden: true,
    children: [
      {
        path: "",
        hidden: true,
        children: [
          {
            path: "/evaluation/enterprise/diagnose",
            component: () =>
              import("@/views/evaluation/enterprise/diagnose/index"),
            name: "enterpriseDiagnose",
            hidden: true,
            meta: { title: "智能诊断", activeMenu: "/evaluation/enterprise" },
          },
        ],
      },
    ],
  },
];

// 动态路由，基于用户权限动态去加载
export const dynamicRoutes = [
  {
    path: "/system/dict-data",
    component: Layout,
    hidden: true,
    permissions: ["system:dict:list"],
    children: [
      {
        path: "index/:dictId(\\d+)",
        component: () => import("@/views/system/dict/data"),
        name: "Data",
        meta: { title: "字典数据", activeMenu: "/system/dict" },
      },
    ],
  },
  {
    path: "/monitor/job-log",
    component: Layout,
    hidden: true,
    permissions: ["monitor:job:list"],
    children: [
      {
        path: "index/:jobId(\\d+)",
        component: () => import("@/views/monitor/job/log"),
        name: "JobLog",
        meta: { title: "调度日志", activeMenu: "/monitor/job" },
      },
    ],
  },
  {
    path: "/tool/gen-edit",
    component: Layout,
    hidden: true,
    permissions: ["tool:gen:edit"],
    children: [
      {
        path: "index/:tableId(\\d+)",
        component: () => import("@/views/tool/gen/editTable"),
        name: "GenEdit",
        meta: { title: "修改生成配置", activeMenu: "/tool/gen" },
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes: constantRoutes,
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0 };
    }
  },
});

export default router;
