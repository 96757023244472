<template>
  <div class="navbar">
    <div class="navbar-logo">
      <logo :collapse="false" />
    </div>
    <hamburger id="hamburger-container" :is-active="appStore.sidebar.opened" class="hamburger-container"
      @toggleClick="toggleSideBar" />
    <screenfull id="screenfull" class="right-menu-item hover-effect hamburger-container" />
    <!-- <breadcrumb id="breadcrumb-container" class="breadcrumb-container" v-if="!settingsStore.topNav" /> -->
    <top-nav id="topmenu-container" class="topmenu-container" v-if="settingsStore.topNav" />

    <div class="right-menu">
      <template v-if="appStore.device !== 'mobile'">
        <!-- <header-search id="header-search" class="right-menu-item" /> -->

        <!-- <el-tooltip content="源码地址" effect="dark" placement="bottom">
          <ruo-yi-git id="ruoyi-git" class="right-menu-item hover-effect" />
        </el-tooltip> -->

        <!-- <el-tooltip content="文档地址" effect="dark" placement="bottom">
          <ruo-yi-doc id="ruoyi-doc" class="right-menu-item hover-effect" />
        </el-tooltip> -->


        <!-- <el-tooltip content="布局大小" effect="dark" placement="bottom">
          <size-select id="size-select" class="right-menu-item hover-effect" />
        </el-tooltip> -->
      </template>
      <div class="avatar-container mr-20px">
        <el-dropdown @command="handleCommand" class="right-menu-item hover-effect" trigger="click">
          <div class="flex items-center mt-16px">
            <!-- <img :src="userStore.avatar" class="user-avatar" /> -->
            <div class="text-[#fff] text-14px mt-2px mr-40px">v 1.0.0</div>
            <div class="text-[#fff] text-14px mt-2px mr-40px" style="display: none;">{{version}}</div>
            <div class="text-[#fff] text-14px">{{ userStore.name }}</div>
            <el-icon color="#fff"><caret-bottom /></el-icon>
          </div>
          <template #dropdown>
            <el-dropdown-menu>
              <el-dropdown-item command="updateInfo">
                <span>个人信息</span>
              </el-dropdown-item>
              <el-dropdown-item command="updatePassword">
                <span>修改密码</span>
              </el-dropdown-item>
              <el-dropdown-item divided command="logout">
                <span>退出登录</span>
              </el-dropdown-item>
            </el-dropdown-menu>
          </template>
        </el-dropdown>
      </div>
    </div>
    <AddModel :show="AddShowModel.show" :title="AddShowModel.title" :id="AddShowModel.id" type="user" @confirm="addAccountConfirm"
      @cancel="AddShowModel.show = false"></AddModel>
    <PasswordModel :show="PasswordShowModel.show" :title="PasswordShowModel.title" :id="PasswordShowModel.id"
      @confirm="PasswordShowModel.show = false" @cancel="PasswordShowModel.show = false"></PasswordModel>
  </div>
</template>

<script setup>
import { reactive } from 'vue'
import { ElMessageBox } from 'element-plus'
import TopNav from '@/components/TopNav'
import Hamburger from '@/components/Hamburger'
import Screenfull from '@/components/Screenfull'
import useAppStore from '@/store/modules/app'
import useUserStore from '@/store/modules/user'
import useSettingsStore from '@/store/modules/settings'
import Logo from './Sidebar/Logo'
import AddModel from '@/views/system/user/components/AddModel.vue';
import PasswordModel from './PasswordModel.vue';

const appStore = useAppStore()
const userStore = useUserStore()
const settingsStore = useSettingsStore()
const version = import.meta.env.VITE_VERSION

// 个人信息弹窗
const AddShowModel = reactive({
  show: false,
  title: '编辑账号',
  id: JSON.parse(sessionStorage.getItem('userInfo')).userId
})

// 修改密码弹窗
const PasswordShowModel = reactive({
  show: false,
  title: '修改密码',
})

function toggleSideBar() {
  appStore.toggleSideBar()
}

function handleCommand(command) {
  switch (command) {
    case "updateInfo":
      AddShowModel.show = true
      break;
    case "updatePassword":
      PasswordShowModel.show = true
      break;
    case "logout":
      logout();
      break;
    default:
      break;
  }
}

function logout() {
  ElMessageBox.confirm('确定注销并退出系统吗？', '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  }).then(() => {
    userStore.logOut().then(() => {
      location.href = '/';
    })
  }).catch(() => { });
}

function addAccountConfirm() {
  AddShowModel.show = false
}
</script>

<style lang='scss' scoped>
.navbar {
  height: 50px;
  overflow: hidden;
  position: relative;
  background: #203864;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  padding-left: 200px;

  .navbar-logo {
    width: 200px;
    position: absolute;
    top: 0;
    left: 0;
  }

  .hamburger-container {
    line-height: 50px;
    height: 100%;
    float: left;
    cursor: pointer;
    transition: background 0.3s;
    -webkit-tap-highlight-color: transparent;

    &:hover {
      background: rgba(0, 0, 0, 0.025);
    }
  }

  .breadcrumb-container {
    float: left;
  }

  .topmenu-container {
    position: absolute;
    left: 50px;
  }

  .errLog-container {
    display: inline-block;
    vertical-align: top;
  }

  .right-menu {
    float: right;
    height: 100%;
    line-height: 50px;
    display: flex;

    &:focus {
      outline: none;
    }

    .right-menu-item {
      display: inline-block;
      padding: 0 8px;
      height: 100%;
      font-size: 18px;
      color: #5a5e66;
      vertical-align: text-bottom;

      &.hover-effect {
        cursor: pointer;
        transition: background 0.3s;

        &:hover {
          background: rgba(0, 0, 0, 0.025);
        }
      }
    }

    .avatar-container {
      .flex items-center {
        margin-top: 5px;
        position: relative;

        .user-avatar {
          cursor: pointer;
          width: 40px;
          height: 40px;
          border-radius: 50%;
          margin-top: 6px;
        }

        i {
          cursor: pointer;
          position: absolute;
          right: -20px;
          top: 25px;
          font-size: 12px;
        }
      }
    }
  }
}
</style>
