<template>
  <el-input v-if="props.type === 'input'" size="large" v-model="value" placeholder="请输入" clearable />
  <el-select v-else-if="props.type === 'select'" v-model="value" size="large" :clearable="!props.clearable">
    <el-option v-for="item of props.data" :key="item.value" :label="item.label" :value="item.value"></el-option>
  </el-select>
  <el-select v-else-if="props.type === 'search'" v-model="value" size="large" filterable remote :loading="loading"
    :remote-method="remoteMethod" clearable>
    <el-option v-for="company of companyList" :key="company.id" :value="company.name"
            :label="company.name"></el-option>
  </el-select>
  <el-date-picker v-else-if="props.type === 'date'" v-model="value" type="date" value-format="YYYY-MM-DD"
    placeholder="请选择" size="large" />
</template>

<script setup>
import { computed, ref } from 'vue';
import { companySearch } from "@/api";

const emit = defineEmits()

const props = defineProps({
  type: String,
  name: String,
  data: Array,
  clearable: Boolean,
  modelValue: ''
})
const loading = ref(false)
const companyList = ref([])
const value = computed({
  get() {
    return props.modelValue;
  },
  set(newValue) {
    emit('update', props.name, newValue);
  }
});

function remoteMethod(value) {
  if (value) {
    loading.value = true
    companySearch({
      keyWord: value
    }).then(res => {
      loading.value = false
      companyList.value = res.data
    })
  }
}
</script>

<style lang='scss' scoped></style>
