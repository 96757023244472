<template>
  <el-dialog :title="props.title" v-model="showType" :width="props.width" append-to-body align-center @close="close">
    <slot name="content"></slot>
    <template #footer>
      <slot name="btn"></slot>
    </template>
  </el-dialog>
</template>

<script setup>
import { watch } from 'vue';

const props = defineProps({
  title: String,
  show: Boolean,
  width: {
    type: String,
    default: '500px'
  }
})

const showType = ref(props.show)

watch(
  () => props.show,
  newValue => {
    showType.value = newValue
  }
)

const emit = defineEmits()

// 确认回调
function submitForm() {
  emit('confirm')
}

// 关闭回调
function close() {
  emit('close')
}
</script>

<style lang='scss' scoped>

</style>
