<template>
  <el-form v-if="seachData.length" :model="formData" ref="queryRef" :inline="true">
    <div class="search-box" :style="{ 'max-height': searchType ? '500px' : '60px' }">
      <el-row :gutter="15">
        <el-col :span="8" v-for="(item, index) of seachData" :key="item.label">
          <el-form-item v-if="item.type === 'btn'">
            <div class="w-full flex justify-end pr-50px">
              <el-button type="primary" icon="Search" size="large" @click="handleQuery">搜索</el-button>
              <el-button icon="Refresh" size="large" @click="resetQuery">重置</el-button>
            </div>
          </el-form-item>
          <el-form-item v-else class="flex1" :label="item.label">
            <searct-item v-model:modelValue="formData[item.name]" :type="item.type" :name="item.name"
              :clearable="item.clearable" :data="item.name === 'status' ? sys_normal_disable : item.data"
              @update="handleChange"></searct-item>
          </el-form-item>
        </el-col>
      </el-row>
      <div v-if="seachData.length > 3" class="expand pointer theme-color" @click="expandRetract">
        <span class="pr-10px">展开</span>
        <el-icon :class="[searchType ? 'rotate' : 'default-rotate']">
          <ArrowDown />
        </el-icon>
      </div>
    </div>
  </el-form>
</template>

<script setup>
import { ref, watchEffect } from 'vue'
import { getDropdownSelect, getDicts } from '@/api/index'
import searctItem from './components/searctItem'
import request from '@/utils/request'
import { clearObjectValue } from '@/utils/common'
import { useRouter } from 'vue-router'

const props = defineProps({
  list: Array,
  queryValues: Array
})
const router = useRouter()
const emit = defineEmits()
const { proxy } = getCurrentInstance();
const { sys_normal_disable } = proxy.useDict("sys_normal_disable");

const seachData = ref(Object.assign([], props.list))

// 插入按钮项
if (seachData.value.length < 2) {
  seachData.value.push({})
  seachData.value.push({ type: 'btn' })
} else {
  seachData.value.splice(2, 0, { type: 'btn' })
}
const queryRef = ref(null)

// 展开收起
const searchType = ref(false)

// 表单参数
const queryParams = {}

// 循环搜索项
props.list.forEach((item, index) => {
  const nowIndex = index < 2 ? index : index + 1
  queryParams[item.name] = null
  if (item.key) {
    // 获取下拉框字典
    getDropdownSelect(item.url, item.key).then(res => {
      seachData.value[nowIndex].data = res.data
    })
  } else
    // 获取接口字典
    if (item.api && item.type !== 'search') {
      request({
        url: item.api,
        method: item.method
      }).then(res => {
        seachData.value[nowIndex].data = res.data.map(sele => {
          return {
            label: sele[item.title],
            value: sele[item.value]
          }
        })
      })
    } else
      // 获取字典
      if (item.dict) {
        getDicts(item.dict).then(resp => {
          seachData.value[nowIndex].data = resp.data.map(p => ({ label: p.dictLabel, value: p.dictValue, elTagType: p.listClass, elTagClass: p.cssClass }))
        })
      }
})

// 动态表单
const formData = reactive(queryParams);

watchEffect(() => {
  props.queryValues && props.queryValues.forEach(item => {
    formData[item.key] = item.value
  })
})

function handleChange(name, value) {
  formData[name] = value
}

// 搜索
function handleQuery() {
  emit('query', formData)
}

// 重置
function resetQuery() {
  clearObjectValue(formData)
  router.replace({query: {}})
  emit('reset')
}

// 展开收起
function expandRetract() {
  searchType.value = !searchType.value
  setTimeout(() => {
    emit('retract', queryRef.value.$el.clientHeight)
  }, 300)
}

</script>

<style lang='scss' scoped>
.el-form--inline {
  .el-form-item {
    width: 100%;
    margin-right: 0 !important;
  }
}

.search-box {
  overflow: hidden;
  position: relative;
  transition: .3s;

  :deep(.el-form-item__content) {
    margin-right: 20px;
  }
}

.expand {
  position: absolute;
  top: 12px;
  right: 0;
}

.default-rotate {
  transform: rotate(0deg);
  transition: .3s;
}

.rotate {
  transform: rotate(180deg);
  transition: .3s;
}
</style>
