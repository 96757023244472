import request from '@/utils/request'

// 客户分页列表
export function getCustomerPage(params) {
  return request({
    url: '/customer/info/page' + params,
    method: 'get'
  })
}

// 客户列表
export function getCustomerList() {
  return request({
    url: '/customer/info/list',
    method: 'get'
  })
}

// 客户下拉框
export function getCustomerLabelSelect() {
  return request({
    url: '/customer/info/labelSelect',
    method: 'get'
  })
}

// 客户详情
export function getCustomerInfo(params) {
  return request({
    url: '/customer/info/detail/' + params,
    method: 'get'
  })
}

// 根据id删除客户信息
export function deleteCustomer(data) {
  return request({
    url: '/customer/info/delete',
    method: 'post',
    data: data
  })
}

// 新增客户
export function addCustomer(data) {
  return request({
    url: '/customer/info/add',
    method: 'post',
    data: data
  })
}

// 编辑客户
export function editCustomer(data) {
  return request({
    url: '/customer/info/edit',
    method: 'post',
    data: data
  })
}

// 导出客户
export function getCustomerExport(params) {
  return request({
    url: '/customer/info/export' + params,
    method: 'post'
  })
}

// 转交他人
export function customerTransformUser(data) {
  return request({
    url: '/customer/info/transformUser',
    method: 'post',
    data: data
  })
}

// 客户名称查重
export function customerExistCustomer(params) {
  return request({
    url: `/customer/info/existCustomer?customerName=${params}`,
    method: 'post'
  })
}