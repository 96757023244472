<template>
  <div class="h-full flex f-d-column">
    <list-search ref="searchRef" v-show="showSearch" :list="props.list" :queryValues="props.query" @query="queryChange"
      @reset="loadList('reset')" @retract="computedTable"></list-search>
    <el-row ref="buttonRef" :gutter="10">
      <el-col :span="12">
        <slot name="action-bar"></slot>
      </el-col>
      <!-- <right-toolbar v-model:showSearch="showSearch" @queryTable="loadList" :columns="columns"></right-toolbar> -->
    </el-row>
    <div>
      <el-table v-loading="loading" :data="data" @selection-change="handleSelectionChange" :height="tableHeight"
        :row-key="rowKeyName">
        <template v-for="item of props.columns">
          <el-table-column v-if="item.type" :type="item.type" :width="item.width || 50" :align="item.align" />
          <el-table-column v-else-if="item.key !== 'handel'" :label="item.label" :align="item.align" :key="item.key"
            :prop="item.key" :min-width="item.width" :sortable="item.sortable" :show-overflow-tooltip="true">
            <!-- 是否 -->
            <template #default="scope" v-if="item.template === 'if'">
              <div class="status-tag">{{
                scope.row.reportFlag ? '是' : '否' }}</div>
            </template>
            <!-- 状态 -->
            <template #default="scope" v-else-if="item.template === 'status'">
              <div class="status-tag" :class="scope.row.status === '1' ? 'status-tag-red' : 'status-tag-green'">{{
                scope.row.status === '1' ? '禁用' : '启用' }}</div>
            </template>
            <!-- 链接 -->
            <template #default="scope" v-else-if="item.template === 'http'">
              <router-link :to="`/system/dict/config/${scope.row.dictId}`">
                <el-link type="primary">{{ scope.row.dictType }}</el-link>
              </router-link>
            </template>
            <!-- 角色 -->
            <template #default="scope" v-else-if="item.template === 'roles'">
              <span v-for="(role, index) of scope.row.roles">{{ index ? `,${role.roleName}` : role.roleName }}</span>
            </template>
          </el-table-column>
          <!-- 操作栏 -->
          <el-table-column v-else :label="item.label" :align="item.align" :prop="item.key" :width="item.width"
            :fixed="item.fixed">
            <template #default="scope">
              <el-button v-for="text of item.list" :key="text.name" link type="primary"
                @click="handleBtn(scope.row, text.name)" v-hasPermi="[text.hasPermi]">{{ text.text }}</el-button>
            </template>
          </el-table-column>
        </template>
      </el-table>
    </div>
    <pagination v-show="total > 0" :total="total" v-model:page="pageParams.pageNum" v-model:limit="pageParams.pageSize"
      @pagination="pageChange" />
  </div>
</template>

<script setup>
import { ref, onMounted, onUnmounted, nextTick } from 'vue'
import { getCustomerExport } from '@/api/index'
import { download } from '@/utils/common'

const props = defineProps({
  columns: Array,
  data: Array,
  total: Number,
  list: Array,
  query: Array,
  rowKeyName: String,
  findList: Function,
  exportName: '列表.xlsx'
})

const emit = defineEmits()
const loading = ref(true);
// 隐藏、线索搜索栏
const showSearch = ref(true)
// 查询参数
const queryParams = ref({})
// 分页参数
const pageParams = ref({
  pageNum: 1,
  pageSize: 20
})

const stop = watchEffect(() => {
  props.query && props.query.forEach(item => {
    queryParams.value[item.key] = item.value
  })
})
stop()

const buttonRef = ref(null)
const searchRef = ref(null)
const searchHeight = ref(0)
const tableHeight = ref(0)

onMounted(() => {
  loadList();
  watchWindow();
});

// 计算表格高度
function computedTable(data) {
  searchHeight.value = data || searchRef.value.$el.clientHeight
  setTimeout(() => {
    const buttonHeight = buttonRef.value.$el.clientHeight
    const bodyHeight = document.documentElement.clientHeight
    tableHeight.value = bodyHeight - searchHeight.value - buttonHeight - 170
  }, 100);
}

onUnmounted(() => {
  // 组件销毁后解绑事件
  window.onresize = null
})

// 监听window
function watchWindow() {
  window.onresize = () => {
    computedTable()
  }
}

function handleBtn(row, name) {
  emit('handle', { row, name })
}

// 点击查询
function queryChange(data) {
  queryParams.value = data
  loadList()
}

// 操作分页
function pageChange(data) {
  pageParams.value.pageNum = data.page
  pageParams.value.pageSize = data.limit
  loadList()
}

// 刷新列表
function loadList(reset) {
  loading.value = true
  const obj = Object.assign(queryParams.value, pageParams.value)
  let params = '?'
  for (let key in obj) {
    if (obj[key] || obj[key] == 0) {
      params += `${key}=${obj[key]}&`
    }
  }
  params = params.substring(0, params.length - 1);
  props.findList(params, reset)
  loading.value = false
  nextTick(() => {
    computedTable()
  })
}

// 选择条数
function handleSelectionChange(selection) {
  emit('check', selection)
};

// 导出
function exportExcel() {
  let params = '?'
  Object.keys(queryParams.value).forEach(key => {
    const value = queryParams.value[key]
    if (value) {
      params += `${key}=${value}&`
    }
  });
  params = params.substring(0, params.length - 1);
  getCustomerExport(params).then(res => {
    download(res.data, '列表.xlsx', 'application/vnd.ms-excel');
  })
}

// 暴露方法
defineExpose({ loadList, exportExcel })
</script>

<style lang='scss' scoped></style>
