<template>
  <dialog-model :title="title" :show="show" @confirm="confirm" @close="cancel">
    <template #content>
      <el-form ref="formRef" :model="form" :rules="rules" label-width="80px">
        <el-form-item label="旧密码" prop="oldPassword">
          <el-input v-model="form.oldPassword" type="password" size="large" placeholder="请输入旧密码" show-password />
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="form.newPassword" type="password" size="large" placeholder="请输入新密码" show-password />
        </el-form-item>
        <el-form-item label="确认密码" prop="rawPassword">
          <el-input v-model="form.rawPassword" type="password" size="large" placeholder="请输入确认密码" show-password />
        </el-form-item>
      </el-form>
    </template>
    <template #btn>
      <div class="dialog-footer">
        <el-button type="primary" @click="confirm">确 定</el-button>
        <el-button @click="cancel">取 消</el-button>
      </div>
    </template>
  </dialog-model>
</template>

<script setup>
import { reactive, ref, watch, toRefs } from 'vue';
import { resetUserPwd } from "@/api";
import { clearObjectValue } from "@/utils/common"

const { proxy } = getCurrentInstance();

const props = defineProps({
  title: String,
  show: Boolean,
})

const emit = defineEmits()
const formRef = ref(null);

// 表单
const form = reactive({
  oldPassword: '',
  newPassword: '',
  rawPassword: ''
})

// 监听modal关闭
watch(
  () => props.show,
  newValue => {
    if (!newValue) {
      emit('cancel')
      clearObjectValue(form)
    }
  }
);

// 表单验证
const rules = reactive({
  oldPassword: { required: true, message: '请输入旧密码', trigger: 'blur' },
  newPassword: { required: true, message: '请输入新密码', trigger: 'blur' },
  rawPassword: getConfirmPwdRule(toRefs(form).newPassword)
})

function getConfirmPwdRule(pwd) {
  const confirmPwdRule = [
    { required: true, message: '请输入确认密码' },
    {
      validator: (rule, value) => {
        if (!isBlankString(value) && value !== pwd.value) {
          return Promise.reject(rule.message);
        }
        return Promise.resolve();
      },
      message: '输入的值与新密码不一致',
      trigger: 'blur'
    }
  ];
  return confirmPwdRule;
}

/** 是否为空字符串 */
function isBlankString(str) {
  return str.trim() === '';
}

// 确认回调
function confirm() {
  proxy.$refs["formRef"].validate(valid => {
    if (valid) {
      resetUserPwd(form).then(res => {
        proxy.$modal.msgSuccess("修改成功");
        emit('confirm')
      })
    }
  });
}

// 取消回调
function cancel() {
  emit('cancel')
}
</script>

<style lang='scss' scoped></style>