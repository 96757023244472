/**
 * 二进制文件导出
 * @param steam 文件流
 * @param name 导出文件名
 * @param format 导出文件格式
 */
export function download(steam, name, format) {
  const blob = new Blob([steam]);
  const link = document.createElement('a');
  link.href = URL.createObjectURL(blob);
  link.download = name;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link); // 下载完成移除元素
  window.URL.revokeObjectURL(link.href); // 释放掉blob对象
}

// 获取对应svg名称
export const iconType = (type) => {
  if (type == 'pdf') {
    return 'img-pdf'
  } else if (type == 'ppt' || type == 'pptx') {
    return 'img-ppt'
  } else if (type == 'doc' || type == 'docx') {
    return 'img-world'
  } else if (type == 'xls' || type == 'xlsx') {
    return 'img-excel'
  } else {
    return 'img-other'
  }
}